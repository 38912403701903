<template>
  <div id="secure-search">
    <div class="vasion-flex-start secure-search-header">
      {{ searchName }}
    </div>
    <div id="secure-search-body">
      <div class="secure-search-selection-div">
        <VasionListSelection
          :available-fields.sync="availableUsers"
          :selected-fields.sync="assignedUsers"
          :allow-field-ordering="false"
          :available-label-text="'All Users'"
          :selected-label-text="'Permitted Users'"
          :unique-id="'secure-search-users'"
        />
      </div>
      <div class="separator-div" />
      <div class="secure-search-selection-div">
        <VasionListSelection
          :available-fields.sync="availableGroups"
          :selected-fields.sync="assignedGroups"
          :allow-field-ordering="false"
          :available-label-text="'All Groups'"
          :selected-label-text="'Permitted Groups'"
          :unique-id="'secure-search-groups'"
        />
      </div>
    </div>
    <div class="vasion-flex-end secure-search-footer">
      <VasionButton
        id="close-button"
        :classProp="'secondary'"
        @vasionButtonClicked="close()"
      >
        Close
      </VasionButton>
      <VasionButton
        id="save-button"
        :classProp="'primary'"
        @vasionButtonClicked="save()"
      >
        Save
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecureSearch',
  props: {
    searchID: {
      type: Number,
      required: false,
      default: 0,
    },
    searchName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      assignedGroups: [],
      assignedUsers: [],
      availableGroups: [],
      availableUsers: [],
    }
  },
  async created() {
    const securityData = await this.$store.dispatch('search/getSearchSecurity', this.searchID)
    this.pushToSecurityArrays(securityData)
  },
  methods: {
    close() { this.$emit('closeClick') },
    pushToSecurityArrays(securityData) {
      if (securityData.availableGroups) {
        securityData.availableGroups.forEach((element) => {
          this.availableGroups.push({
            value: element.iID,
            name: element.sName,
          })
        })
      }

      if (securityData.assignedGroups) {
        securityData.assignedGroups.forEach((element) => {
          this.assignedGroups.push({
            value: element.iID,
            name: element.sName,
          })
        })
      }

      if (securityData.availableUsers) {
        securityData.availableUsers.forEach((element) => {
          this.availableUsers.push({
            value: element.iID,
            name: element.sName,
          })
        })
      }

      if (securityData.assignedUsers) {
        securityData.assignedUsers.forEach((element) => {
          this.assignedUsers.push({
            value: element.iID,
            name: element.sName,
          })
        })
      }
    },
    async save() {
      const assignedUserIds = this.assignedUsers.map(user => user.value).filter(e => e != null)
      const assignedGroupIds = this.assignedGroups.map(group => group.value).filter(e => e != null)
      const payload = {
        searchID: this.searchID,
        assignedUsers: assignedUserIds,
        assignedGroups: assignedGroupIds,
      }
      const response = await this.$store.dispatch('search/saveSearchSecurity', payload)
      this.$emit('saveClick', response.Value)
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';
  .secure-search-selection-div {
    height: 390px;
    width: 100%;
  }

  .separator-div {
    height: 20px;
  }
  #secure-search-body {
    padding: 15px 10px 0px 40px;
    margin: 50px 0px;
  }
  #secure-search {
    overflow: auto;
    clear: both;
  }
  .secure-search-header {
    font-weight: bold;
    font-size: 32px;
    height: 50px;
    width: 100%;
    z-index: 100;
    padding: 20px 40px;
    background-color: $white;
    position: absolute;
    top: 0;
  }
  .secure-search-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $white;
    padding: 10px 0px;
  }
</style>
