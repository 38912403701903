<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>{{ headerText }}</h1>
      <div class="btns-container">
        <VasionButton
            v-if="canConfigureSearches"
            id="btnNewFTSearch"
            class="last-btn"
            classProp="primary"
            @vasionButtonClicked="editSearch(0, searchListTypeID)"
          >
            {{ newSearchButtonText }}
          </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="ColumnValues"
          :hideColumns="hiddenColumns"
          :tableRows="tableData.Rows.Values"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
    </div>
    <md-dialog id="secure-search" :md-active.sync="showSecureSearch" :md-click-outside-to-close="false">
      <SecureSearch
        :searchName="secureSearchName"
        :searchID="secureSearchId"
        @closeClick="showSecureSearch = !showSecureSearch"
        @saveClick="secureSearchSaved"
      />
    </md-dialog>
    <md-dialog id="confirmSearchDelete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="deleteSearchAndToggle()" />
    </md-dialog>
    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
  </div>


</template>

<script>
import Loading from 'vue-loading-overlay';
import { SearchTypes } from '@/enums/searchTypes'
import SecureSearch from '@/views/admin/searchSetup/SecureSearch.vue'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'SearchSetup',
  components: {
    Loading,
    SecureSearch,
  },
  data: function () {
    return {
      ColumnValues: [
        'SearchID',
        'SearchType',
        'Name',
        '_VasionEllipsisButton_',
      ],
      deletePromptMessage: '',
      deleteSearchID: 0,
      deleteSearchName: '',
      fullTextTypeID: SearchTypes.FullText,
      hiddenColumns: [
        'SearchID',
        'SearchType',
      ],
      indexTypeID: SearchTypes.Index,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      secureSearchId: 0,
      secureSearchName: '',
      showDeleteDialog: false,
      showSecureSearch: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarText: '',
      snackbarTitle: '',
      tableData: {
        Rows: {
          Values: [],
        },
      },
    }
  },
  computed: {
    canConfigureSearches() { return this.$store.getters['common/canConfigureSearches'] },
    canRefreshDTSearchFiles() { return this.$store.getters['common/canAccessSearchSetup'] },
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    headerText() { return this.searchListTypeID === SearchTypes.FullText ? 'Full Text Search Setup' : `${$formsLabel} Search Setup` },
    newSearchButtonText() { return this.searchListTypeID === SearchTypes.FullText ? 'New Full Text Search' : `New ${$formsLabel} Search` },
    searchList() {
      return this.searchListTypeID <= 0 ? this.$store.state.search.adminSearchList : this.$store.state.search.adminSearchList.filter((search) => {
        return search.Type === this.searchListTypeID
      })
    },
    searchListTypeID() { return this.$store.state.search.adminSearchListTypeID },
  },
  created() {
    this.refreshTable()
    this.$store.dispatch('vault/getVaultList')
    this.$store.dispatch('attributeForm/getForms')
    this.$store.dispatch('security/setEllipsisButtonConfig', 'search-setup')
  },
  methods: {
    deleteSearchAndToggle() {
      if (this.deleteSearchID > 0) {
        this.$store.dispatch('search/deleteSearch', this.deleteSearchID)
      }

      this.deleteSearchID = 0
      this.deleteSearchName = ''
      this.toggleDeleteDialog()
      this.refreshTable()
    },
    deleteSearchClick(searchID, searchName) {
      this.deleteSearchID = searchID
      this.deleteSearchName = searchName

      this.toggleDeleteDialog()
    },
    editSearch(searchID, searchType) {
      if (searchType === this.indexTypeID) {
        this.$router.push({ name: 'IndexSearchSetup', params: { searchID: searchID } })
      } else {
        this.$router.push({ name: 'TextSearchSetup', params: { searchID: searchID } })
      }
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, searchType, name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-search-setup-button':
            this.editSearch(id, searchType)
            break;
          case 'delete-search-setup-button':
            this.deletePromptMessage = `Are you sure you want to delete "${name}"?`
            this.deleteSearchClick(id, name)
            this.showDeleteDialog = true
            break;
          case 'secure-search-setup-button':
            this.secureSearchClick(id, name)
            break;
          case 'refresh-search-setup-button':
            this.refreshSearchIndexClick(id, name)
            break;
          default:
            break;
        }
      }
    },
    hideSnackbar() { this.showSnackbar = false },
    async refreshSearchIndexClick(searchID, searchName) {
      this.snackbarText = ''
      const result = await this.$store.dispatch('search/updateFullTextSearchIndex', searchID)

      if (result && result.Value && result.Value === 'True') {
        this.snackbarImage = true
        this.snackbarText = 'The full text search refresh request has been submitted.'
      } else {
        this.snackbarImage = false
        this.snackbarText = `Unable to update Index Files for ${searchName}`
      }

      this.showSnackbar = true
    },
    async refreshTable() {
      this.isLoading = true

      await Promise.all([
        this.$store.dispatch('search/loadAdminSearchList'),
        this.$store.dispatch('search/getFolderSearchConfigurations'),
      ])

      this.tableData = {
        Rows: {
          Values: [],
        },
      }
      this.tableData.Rows.Values = this.searchList.map((searchElement) => {
        const dataRow = [
          searchElement.SearchId,
          searchElement.Type,
          searchElement.Name,
          'VasionEllipsisIcon',
        ]

        const retElement = {
          Values: dataRow,
          ValueType: 'String',
        }

        return retElement
      })

      this.isLoading = false
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      const [id, searchType] = data.Values
      this.editSearch(id, searchType)
    },
    secureSearchClick(id, name) {
      this.showSecureSearch = true
      this.secureSearchId = id
      this.secureSearchName = name
    },
    secureSearchSaved(result) {
        this.showSnackbar = true
        this.snackbarTitle = result === 'True' ? 'Success' : 'Error'
        this.snackbarText = result === 'True' ? 'Your changes have been saved' : 'There was an error saving your changes'
        this.snackbarImage = result === 'True'
        this.showSecureSearch = !this.showSecureSearch
    },
    toggleDeleteDialog() { this.showDeleteDialog = !this.showDeleteDialog },
    typeInEnglish(typeID) { return typeID === this.fullTextTypeID ? 'Full Text' : 'Index' },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
}

#secure-search {
  width: 100%;
  overflow: auto;
}
</style>
